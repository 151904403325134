.splide {
  $root: &;

  &--draggable {
    > #{$root}__track {
      > #{$root}__list {
        > #{$root}__slide {
          user-select: none;
        }
      }
    }
  }
}