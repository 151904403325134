.c-scrollbar {
    position: absolute;
    right: 0;
    top: 0;
    width: 11px;
    height: 100%;
    transform-origin: center right;
    transition: transform 0.3s, opacity 0.3s;
    opacity: 0;

    &:hover {
        transform: scaleX(1.45);
    }

    &:hover, .has-scroll-scrolling &, .has-scroll-dragging & {
        opacity: 1;
    }

    [data-scroll-direction="horizontal"] & {
        width: 100%;
        height: 10px;
        top: auto;
        bottom: 0;
        transform: scaleY(1);

        &:hover {
            transform: scaleY(1.3);
        }
    }

}

.c-scrollbar_thumb {
    position: absolute;
    top: 0;
    right: 0;
    background-color: black;
    opacity: 0.5;
    width: 7px;
    border-radius: 10px;
    margin: 2px;
    cursor: grab;

    .has-scroll-dragging & {
        cursor: grabbing;
    }

    [data-scroll-direction="horizontal"] & {
        right: auto;
        bottom: 0;
    }

}
