
  .toggle-pane {
    position: relative;
    padding: 0.5rem 1rem;
    background: #212121;
    font-size: 1.2rem;
  }

  .pane {
    z-index: 220;
    position: fixed;
    top: unset;
    right: -460px;
    padding: 0;
    height: 100vh;
    width: 450px;
    background: white;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
    transition: 0.5s all ease;
    .modal-card {
      margin:0;
    }
    @include until ($tablet) {
      height: 90vh;
      margin-top: 5vh;
      width: 90%;
      right: -90%;
    }
    @include from ($desktop) {
      right: -800px;
      width: 780px;
    }
    iframe {
      width:100%;
      height: 100%;
      position: relative;
    }
    &.is-panactive {
      right: 0%;
      @include until ($tablet) {
        right: 5%;
      }
      .clospan {
        opacity: 0.6;
        width: 100vw;
        height: 100vh;
        background: #000;
        position: fixed;
        right: 0;
        top:0;
        z-index: -1;
      }
    }

    .modal-card {
      width: 100%;
      height: 100%;
      .modal-card-head {
       border-radius: 0;
      }
    }

  }

