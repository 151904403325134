



.containerThree {
  height: 100vh;
  width: 100vw;
  position: relative;
}


.webgl
{
  position: absolute;
  top: 0;
  left: 0;
  outline: none;
  //transition: width 2s ease-in-out;
}
#map_canva {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow:hidden;
  z-index: 3;
  max-height: 100vh;
  max-width: 100vw;
  //transition: width 1s ease-in-out;
  .back2map {
    position:absolute;
    bottom:8rem;
    height: 4rem;
    width: 4rem;
    right:1rem;
    border-radius: 3rem;
    opacity: 0;
    transition: opacity 0.25s ease;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span {
      font-size: 0.72rem;
      line-height: 0.84rem;
      text-transform: uppercase;
    }
  }
  &.isactCanSize {
    .back2map {
      z-index: 4;
      opacity: 1;
      display: flex;
      background: #fff;

    }
  }
}

.content__loader {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: $white;
  opacity: 1;
  transition: opacity 1s;
  pointer-events: none;
  .percent__title {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 4rem;
    width: 200px;
    height: 200px;
    transform: translate(-50%,-50%);
    color: $picblue;
    text-align: center;
  }
  &.ended {
    opacity: 0;
  }
  &.untop {
    display:none;
    z-index: -200;
  }
}

.loading-bar
{
  position: absolute;
  top: 50%;
  width: 100%;
  height: 2px;
  background: $picblue;
  transform: scaleX(0.3);
  transform-origin: top left;
  transition: transform 0.5s;
}

.loading-bar.ended
{
  z-index: 200;
  transform: scaleX(0);
  transform-origin: 100% 0;
  transition: transform 1.5s ease-in-out;
}





.point
{
  position: absolute;
  top: 50%;
  left: 50%;

  transition: transform 0.1s;
  /* pointer-events: none; */
}

.point .label
{
  text-indent: -6000px;
  position: absolute;
  top: -20px;
  left: -20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid #ffffff00;
  color: #000000;
  font-family: Helvetica, Arial, sans-serif;
  text-align: center;
  line-height: 40px;
  font-weight: 100;
  font-size: 14px;
  cursor: pointer;
  transform: scale(0, 0);
  transition: transform 0.3s;
}

.point.clone .label
{
  text-indent: 0;
  border-radius: 50%;
  background: #FFFF0077;
  border: 1px solid #ffffff77;
  color: #000000;
}
.point:hover .text {
  opacity: 1;
  transition: opacity 0.3s ease-in;
}
.point .text
{
  transition: opacity 0.3s ease-out;
  opacity:0;
  position: absolute;
  top: 0px;
  left: 50%;
  width: 110px;
  height: 110px;
  padding: 0px;
  border-radius: 4px;
  background: transparent;
  border: 1px solid transparent;
  line-height: 1.3em;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: bold;
  pointer-events: none;
  transform: translate(-50%,-50%);
}

.point.point-0 {
  position: fixed ;
  top: auto;
  left: auto;
  bottom:20px;
  right:20px;
  z-index: 0;
  opacity: 0;
  pointer-events: none;
  transform: translate(0,0) !important;
}

.point .label
{
  transform: scale(1, 1);
}


svg circle {
  fill: transparent;
  stroke-width: 20px;
  stroke: #0000FF;
}
path {
  fill: transparent;
  stroke-linejoin: round;
}

text {
  //fill: #FFFF00;
  fill: #FFF;
}

#circle svg { position: absolute; left: 0; top: 0; width: 100%; height: 100%;

  -webkit-animation-name: rotate;
  -moz-animation-name: rotate;
  -ms-animation-name: rotate;
  -o-animation-name: rotate;
  animation-name: rotate;
  -webkit-animation-duration: 9s;
  -moz-animation-duration: 9s;
  -ms-animation-duration: 9s;
  -o-animation-duration: 9s;
  animation-duration: 9s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -ms-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;

}

@-webkit-keyframes rotate {
  from { -webkit-transform: rotate(360deg); }
  to { -webkit-transform: rotate(0); }
}
@-moz-keyframes rotate {
  from { -moz-transform: rotate(360deg); }
  to { -moz-transform: rotate(0); }
}
@-ms-keyframes rotate {
  from { -ms-transform: rotate(360deg); }
  to { -ms-transform: rotate(0); }
}
@-o-keyframes rotate {
  from { -o-transform: rotate(360deg); }
  to { -o-transform: rotate(0); }
}
@keyframes rotate {
  from { transform: rotate(360deg); }
  to { transform: rotate(0); }
}



#actCanSize {
  position: absolute;
  z-index: 0;
  top: 0  ;
  left: 0;

  @include until ($desktop) {
    width: 50%;
    height: 100%;
  }
  @include from ($desktop) {
    width: 50%;
    height: 100%;
  }
}

.is-flex-vertical {
  display: flex;
  flex-direction: column;
}
.is-flex-vertical-until-tablet {
  @include until ($desktop) {
    flex-direction: column-reverse;
  }
}
.is-align-content-flex-center {
  align-content: center;
  align-items: center;
}
.is-flex-vert-until-tablet {
  @include until ($desktop) {
    flex-direction: column;
    text-align: center;

  }
}
.is-flex-vert-only-mobile {

  flex-direction: column;
  text-align: center;
  @include from ($tablet) {
    flex-direction: row;
    text-align: left;
  }
}

#pointsMenuCont {
  position: absolute;
  bottom: 0;
  height: 8rem;
  z-index: 5;
  >.container {
    height:100%
  }
}
#pointmenu {
  border-bottom: 1px solid $gray;
  position:absolute;
  bottom:0rem;
  width: 100%;
  margin: auto;
  float: none;
  left: 0%;
  display: flex;
  justify-content: space-between;
  align-content: stretch;
  height:7rem;
  font-family: 'urbanist';
  font-weight: 600;
  color:$black;
  text-align: left;
  z-index: 5;
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  .point {
    padding: 1rem;
  }
  .point:not(.point-0) {
    position: relative;
    height: 100%;
    display: flex;
    top: auto;
    left: auto;
    width: 100%;
    display: flex;
    align-items: center;
    border-left: 1px solid transparent;
    cursor: pointer;
    &:hover {
      color: $picblue;
    }
    .titlemenu {
      font-size: 0.8rem;
      text-transform: uppercase;
      width:auto;
      max-width: calc(100% - 2rem);
    }
    i {
      font-size: 3.4rem;
      padding-right: 0.5rem;
    }
  }

  &.active {
    background:$white;
    border-top: 1px solid $gray;
    border-right: 1px solid $gray;
    .point:not(.point-0) {
      border-left: 1px solid $gray;
    }
    .point.active {
      background: $primary;
      color: $white;
    }
  }
}


.tab-content {
  display: block;
  float: right ;
  width: 50%;
  position: absolute;
  right: 0;
  top: 0;
  height:100% ;
  z-index: 1;
  .tabsForPoint {
    opacity:0;
    top: -150px;
    height: 100%;
    max-height: 100vh;
    overflow: scroll;
    color: $black;
    width: 100%;
    position: absolute;
    padding: 4rem;
    .back2map {
      display: block;
    }
    &.active {
      z-index: 5;
    }
  }
}
