
#sm-banner {
  display: none;
  width: 100%;
  aspect-ratio: 2/1;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include until ($desktop)  {
    display: block;

  }
}


.hero-area {
  height: 100vh;
  width: 100vw;
  float: left;
  position: relative;

  &#mapArea {

    @include until ($desktop)  {
      display: none;
    }
  }
  @include until ($tablet)  {
    overflow: hidden;
    height: 66vh;
  }

  // @include until ($widescreen)  {
  //   overflow: hidden;
  //   height: 100vh;
  // }
  //
  // @include from ($widescreen)  {
  //   overflow: hidden;
  //   height: 100vh;
  // }


  &.is-intern {
    > .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      z-index: 2;
      width: 100%;
    }
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: 60vh;
    height: auto;
    figure {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      &:after,&:before {
        display: block;
        content: "";
        background:$highblue ;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        mix-blend-mode: hard-light;
        opacity: 0.5;
      }
      &:before {
        position: absolute;
        z-index: 1;
        mix-blend-mode: multiply;
        opacity: 0.8;
      }
      img {
        position: relative;
        object-fit: cover;
        width: 100%;
        height: 100%;
        max-width: none;
        max-height: none;
        object-position: top;
      }
    }
    h1 {
      font-size: 2.2rem;
      font-weight: 800 !important;
      line-height: 1.1;
    }
    @include from ($desktop)  {
      min-height: 60vh;
      height: auto;
      h1 {
        font-size: 2.7rem;
        font-weight: 800 !important;
      }
    }
    @include from ($widescreen)  {
      min-height: 60vh;
      height: auto;
      h1 {
        font-size: 3.2rem;
        font-weight: 800 !important;
      }
    }

  }

}

.layer {
  position: absolute;
  z-index: 1;
}


.layer-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  &:before {
    content:"";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba($black,0.5);
    top:0;left:0;
    z-index: 1;
  }
}
