@import "styles_loco/base";
@import "styles_loco/scrollbar";

#wrapper {
  overflow: hidden;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#scroll-container {
  position: absolute;
  overflow: hidden;
  width: 100%;
  // height: 300%;
  // z-index: 10;
  // display: flex;
  // justify-content: center;
  // backface-visibility: hidden;
  // transform-style: preserve-3d;

  background-image:
          linear-gradient(rgba(255,255,255,.07) 2px, transparent 2px),
          linear-gradient(90deg, rgba(255,255,255,.07) 2px, transparent 2px),
          linear-gradient(rgba(255,255,255,.06) 1px, transparent 1px),
          linear-gradient(90deg, rgba(255,255,255,.06) 1px, transparent 1px);
  background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
  background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
}

.is-anScr {
  opacity: 0;
}