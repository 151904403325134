// Typography

$size-1: 3rem !default
$size-2: 2.5rem !default
$size-3: 2rem !default
$size-4: 1.5rem !default
$size-5: 1.37rem !default
$size-6: 1.15rem !default
$size-7: 0.75rem !default

$weight-light: 300 !default
$weight-normal: 400 !default
$weight-medium: 500 !default
$weight-semibold: 600 !default
$weight-bold: 700 !default
$weight-black: 800 !default

// Spacing

$block-spacing: 1.5rem !default

// Responsiveness

// The container horizontal gap, which acts as the offset for breakpoints
$gap: 32px !default
$phoneto: 420px !default
// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 769px !default
// 960px container + 4rem
$desktop: 960px + (2 * $gap) !default
// 1152px container + 4rem
$widescreen: 1344px + (2 * $gap) !default
$widescreen-enabled: true !default
// 1344px container + 4rem
$fullhd: 1680px + (2 * $gap) !default
$fullhd-enabled: true !default

// Miscellaneous

$easing: ease-out ;
$radius-small: 8px ;
$radius-medium: 12px ;
$radius: 18px ;
$radius-large: 24px ;
$radius-rounded: 290486px ;
$speed: 86ms ;

$variable-columns:true;


// Color Variables
// Set your brand colors
$danube: #66A0D4;
$havblue: #5F9ED7;
$havbluelight: #E2F1FF;
$picblue:#0000FF;
$iceblue:#00D2FF;
$skyblue:#3A7BD5;
$highblue:#0042FF;
$paleblue:#CAEAEE;
$pastblue:#A8EBFD;
$pastsky:#A8DCF3;
$whiteblue:#E4F4F6;
$pastviolet:#BCD5FC;
$pastred:#FAC2CF;
$lightblue:#A8D8DE;
$topblue:#1B85D2;
$matisse:#225EAA;
$strongblue:#0088CB;
$deepblue:#2E3782;
$light-sky:#F0FBFE;
$pink: #EB1D59;
$red : #FF0000;
$yellow: #FFFF00;
$orange:#F05F19;
$lightpink:#EAADC1;
$brown: #757763;
$beige-light: #000;
$beige-lighter: #EFF0EB;
$grey-light: #CCCCCC;
$light-gray:#F6F6F8;
$white: #FFF;

// Update Bulma's global variables
$grey-dark: $brown;
$grey-light: $beige-light;
$primary: $picblue;
$link: $picblue;
$info:$picblue;
// Update some of Bulma's component variables
//$body-background-color: $beige-lighter;
$body-background-color: $white;
$control-border-width: 1px;


$primary-color: $primary;
$secondary-color: $pink;
$text-color: #3C4653;
$text-color-dark: #242949;
$text-color-light: #999999;
$text-strong:#000;
$body-color: #000;
$border-color: #c7c7c7;
$black: #000;
$white: #fff;
$gray: #eaeaea;
$midgray:#D8D8D8;
$navbar-item-color: lighten($text-color-dark, 30%);
$navbar-item-color: $black;
$transparent : rgba(250,250,250,0);
$info:$secondary-color;
$navbar-background-color:$white;
$navbar-item-hover-color:$primary-color;
$navbar-item-hover-background-color:$secondary-color;

$navbar-height: 5rem !default
$navbar-padding-vertical: 1rem !default
$navbar-padding-horizontal: 2rem !default

$roseau: ("navagora": ($transparent, $havblue),
  "bluetrans": ($primary, $white),
  "pinktrans": ($pink, $white),
  "pastblue": ($pastblue, $black),
  "pastsky": ($pastsky, $white),
  "pastviolet": ($pastviolet, $white),
  "pastred": ($pastred, $white),
  "lightpink": ($lightpink, $black),
  "topblue": ($topblue, $black),
  "whiteblue": ($white, $deepblue),
        "yellow": ($yellow, $black)
  );

$custom-colors:$roseau;

$widescreen-enabled: true;
$fullhd-enabled: true;

$footer-background-color :$light-gray;
$footer-padding: 3rem 1.5rem 3rem;

//hr
$hr-background-color:rgba($text-color-light,0.5);
$hr-height:1px;
// Font Variables
$body-font-size:18px;
$body-color:$text-color;
$title-color:$text-color;
$primary-font: 'Urbanist', sans-serif;
$secondary-font: 'Manrope', sans-serif;
$tertiary-font: 'Poppins';
$icon-font: 'themify';
$family-secondary:$tertiary-font;
$body-family:$primary-font;
//card
//$card-color: $text !default;
$card-background-color: $light-sky;
$card-shadow: 0 0px 1px 1px rgba($brown, 0.1), 0 0px 0 1px rgba($primary-color, 0.02);
$card-radius: 0.25rem !default;
$card-overflow: hidden !default;

$card-header-background-color: $white;
//$card-header-color: $text-strong !default;
$card-header-padding: 0.75rem 1rem ;
$card-header-shadow:  0 0px 1px 1px rgba($brown, 0.1) ;
$card-header-weight: $weight-bold ;

$card-content-background-color: transparent ;
$card-content-padding: 1.5rem ;

$card-footer-background-color: transparent ;
//$card-footer-border-top: 1px solid $border-light !default;
$card-footer-padding: 0.75rem ;

$card-media-margin: $block-spacing;


// project varaibles
$radius-big : 30px;


// Modal minimal setup
$transition-duration: .3s;
$transition-duration-newsPaper: .7s;
$transition-duration-3dslit: .5s;

$modal-perspective: 1300px;
$modal-bg-color: rgba($black,.86);


//////space
$spacing-values: ("0": 0, "1": 0.25rem, "2": 0.5rem, "3": 0.75rem, "4": 1rem, "5": 1.5rem, "6": 3rem, "x":8rem) !default