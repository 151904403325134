
@import 'variables';
//@import 'derived-variables';


//@import "bulma";
// Import only what you need from Bulma
//@import "node_modules/bulma/sass/utilities/_all.sass";
//@import "node_modules/bulma/sass/base/_all.sass";
//@import "node_modules/bulma/sass/elements/button.sass";
//@import "node_modules/bulma/sass/elements/container.sass";
//@import "node_modules/bulma/sass/elements/title.sass";
//@import "node_modules/bulma/sass/form/_all.sass";
//@import "node_modules/bulma/sass/grid/_all.sass";
//@import "node_modules/bulma/sass/components/all.sass";
//@import "node_modules/bulma/sass/layout/hero.sass";
//@import "node_modules/bulma/sass/layout/section.sass";

@import "node_modules/bulma/bulma.sass";
@import "node_modules/bulma-responsive-tables/bulma-responsive-tables";

//// tooltip
@import "node_modules/@creativebulma/bulma-tooltip/src/sass/index.sass";

//// tooltip
@import "node_modules/@creativebulma/bulma-divider/src/sass/index.sass";
////


//// font
@import "common";


@import "utils/mixins/fontmixin";
//// font
@import "components/fonts";

//icons

@import "utils/icomoon/style";
////

//// bulma modal
@import "components/bulma-modal-fx/modal-fx";
////

//// slide panel
@import "components/slideout/slideout";
////

@import 'components/bulma-space-rwd';

@import 'components/colorsschem';

@import 'components/locomotive-scroll';

@import 'components/splide/core/splide-core';

//// Navigation
@import "templates/navigation";
////
@import "templates/hero-area";

@import "components/mapApp/mapapp";


.container {

  @include until ($desktop) {
    max-width: 100vw;
    overflow: hidden;
  }
}
.navbar {
  .navbar-item img {
    height: 78px;
    width: 358px;
    max-height: none;
  }
  .logo {
    display: block;
    width: 358px;
    height: 70px;
    background-image: url("/static/images/logo.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
    @include mobile() {
      width: 70vw;
      height: 50px;
      background-position: center left;
    }
  }
  &.invNav {
    .logo {
      background-image: url("/static/images/logo-white-2.png");
    }
  }

  &.activeScroll {
    .logo {
      background-image: url("/static/images/logo-white-2.png");
    }
  }
}
.footer {
  clear: both;
}


.dg.ac  {
  z-index: 100 !important;
}



///////menu
.is-social-footer {
float:right
}

.is-bread {
  li:last-child{
    display:none;
  }
  a {
    color:$yellow;
    font-size:1.2rem;
  }
  .separator {
    color:$white;
  }
}
#menuOpen {
  position: absolute;
  top:0;
  right:0;
  padding:15px;
  padding: 15px;
  z-index: 5;
  background:red;
  color:white;
}
#menuBounds {
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left:50%;
  z-index:1;
}


.navbar.is-navagora {
  align-items: stretch;
  display: flex;
  @include mobile {
    position: absolute;
  }
  .navbar-menu {
    display: flex;
    align-items: stretch;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: stretch;
    @include until ($desktop) {
      justify-content: flex-end;
    }
    @include mobile {
      background-color: transparent;
      box-shadow: 0 0 0;
      padding: 0.25rem 0;
    }
    .navbar-item {
      align-items: center;justify-content: flex-end;margin-left:auto;
      &.menuTap {
        @include mobile() {
         .buttons {
           width: 100%;
           div:first-child {
             display: none;
           }
         }
         padding: 0;
        }
      }
    }
    @include until ($desktop) {
      .navbar-item:first-child {
        display: none;
      }
    }
  }
  .navbar-burger {
    display: flex;
    font-size:21px;
    text-transform: uppercase;
    color:$picblue;
    justify-content: center;
    align-items: center;
    width: 160px;
    @include mobile(){
      width: 80px;
    }
    > div {
      padding: 0 1rem;
      position: relative;
      @include mobile(){
        width: 100%;
      }
    }
    span {
      color: $picblue;
      height: 3px;
      width: 48px;
      left: initial;
      &:nth-child(1) {
        top:calc(50% - 9px)
      }
      &:nth-child(3) {
        top:calc(50% + 7px);
        width: 32px;
      }
    }
    &:hover {
      background-color:transparent;
      color:$white;
      span {
        color: $white;
      }
    }
  }
  &.invNav {
    .navbar-burger {
      color: $white;
      span {
        color: $white;
      }
    }
  }
  &.activeScroll {
    background: $picblue;

    .navbar-burger {
      color: $white;
      @include mobile() {
        height: 3rem;
      }
      span {
        color: $white;

        @include mobile() {
          left: calc(0%);
        }
      }
    }
  }
}


///////


.tab-content {
  .content {
    position: absolute;
    opacity: 0;

    &:first-child {
      opacity: 1;
    }
  }
}


////remove gui
.dg.ac {display:none}



.navbar.is-navagora {
  pointer-events: none;
  .navbar-brand {
    pointer-events: all;
  }
  .navbar-end {
    pointer-events: all;
  }
}



////// .tile:not(.is-child)

.section {
  float: left;
  width: 100vw;
  clear: both;
  position: relative;
  z-index: 1;
  padding:0 ;
  &.contentcont {
    &::after {
      content:'';display: block;
      width: calc(100vw + 0px);
      height: 1px;
      position: absolute;
      bottom: -1px;
      left: 50%;
      transform: translate(-50%,0);
      background: #EDEDED;
    }
  }
}
.tile {
  padding: 0 !important;
  margin: 0 !important;
  &.post {
    padding: 2rem !important;
  }
}
.tile:not(.is-parent):not(.is-ancestor) {

  position: relative;
  &:before {
    z-index: -1;
    display: block;
    content:'';
    height: calc(100% + 2px);
    width: calc(100% + 2px);
    top: -1px;
    left: -1px;
    border:1px solid #EDEDED;
    position: absolute;
    background: #FFF;
  }
}
.tile.is-vertical > .tile.is-child:not(:last-child) {
  margin-bottom: 0 !important;
}
.tile.is-ancestor {

  position: relative;
  &:before {
    display: block ;
    content:'';
    width: 100vw;
    top: -1px;
    position: absolute;
    z-index: -1;
    background: #EDEDED;
    height: 1px;
    left: 50%;
    transform: translate(-50%,0);
  }
}

.content-tile {
  padding: 2rem;
}


///slider
.row {
  display: flex;
  max-width: 100%;
  overflow: hidden;
}
/// draggable


.carousel {
  img {
    max-width: 100%;
    max-height: 100%;
  }
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .carousel-item {
    position: absolute;
    font-size: 5rem;
    width: 100%;
    height: 100%;
    background: lightgray;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    &:nth-child(even) {
      background: lightblue;
    }
  }
}

.carousel-wrapper {
  position: relative;
  outline: 3px solid black;
}

.ratio-16by9 {
  padding-top: calc(9/16 * 100%);
  width: 100%;

  > div {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.ratio-1by1 {
  padding-top: 100%;
  width: 100%;

  > div {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.carousel-action {
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  padding: 10px;
  border: 1px solid black;
  cursor: pointer;

  &:first-child {
    left: 0;
  }

  &:nth-child(2) {
    right: 0;
  }
}

@function rand($min, $max) {
  @return (random($max - $min) + $min);
}

.background-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  height: 100%;
  width: 100%;
}


//////////
.slide-article {
  display: block;
  max-width: 100%;
  @include until ($desktop) {
  .splide__slider {
    position: relative;
    max-width: 100vw;
    overflow: hidden;
  }
  }
}

.event-splide {
  .card { margin:3rem 1.5rem;}
}

.splide__arrows {
  position: absolute;
  top: 50%;
  z-index: 10;
  width: 100%;
  width: 105%;
  left: -2.5%;
  button {
    border:none;
    background: none;
    font-size: 1.3rem;
    color: $skyblue;
    &.splide__arrow--prev {
      float: left;
    }
    &.splide__arrow--next {
      float: right;
    }
  }
}

#splide-part {
  .splide__slide {
    display: flex;
    align-items: center;
    a {
      @include until ($desktop) {
        padding: 0 ;
      }
      padding: 1rem ;
    }
  }
}

#splideEvents-track {
  .splide__slide {
    &::after {
      content:"";
      display: block;
      height: 100%;
      width: 1px;
      background: rgba($gray,0);
      position: absolute;
      left:calc(100% - 1px);
      top:0;
      transition: background 0.5s;
    }
    &.is-active {
    &::after {
      background: $gray;
    }
  }
  }
}

.arrowslider {
  display: flex;
  position: absolute;
  bottom:2rem;
  right: 2rem;
  >div {
    width: 1.5rem;
    a {
      color: $text-color-light;
      &:hover {
        color: $text-color-dark;
      }
    }
    &:last-child {
      text-align: right;
    }
  }
  &::after {
    background: $midgray;
    content:'';
    display: block;
    width: 1px;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform:  translate(-50%,-50%) skew(-30deg);
  }
}



.level.is-online {
  flex-wrap: wrap;
}

.fullvid {
  float: left;
  width: 100vw;
  #playVid {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}

.footer {

  .footernav {
    display: flex;
    font-family: 'glacial';


    .navbar-foot {
      display: flex;
      flex-direction: column;
      font-size: 0.75rem;
      align-items: flex-start;
      width: calc(100% / 7);
      font-smooth:subpixel-antialiased;
      -moz-osx-font-smoothing: auto;
      -webkit-font-smoothing: subpixel-antialiased;
      .nv1 {
        font-size: 0.8rem;
        color:$yellow;
        text-transform: uppercase;
        font-weight: bold;
      }
      a:hover {
        background: transparent;
        color: $yellow;
        font-smooth:subpixel-antialiased;
      }
      @include until ($desktop) {
        align-items: center;
      }
    }
    .navbar-link,.navbar-item {

      padding: 0.25rem 0.25rem;
    }
    .navbar-item {
      display: flex;
      flex-direction: column;
      font-size: 0.75rem;
      align-items: flex-start;
      color: $white;
      @include until ($desktop) {
        align-items: center;
        text-align: center;
      }
    }
    @include until ($desktop) {
      width: 70vw;
      margin: auto;
      flex-direction: column;
      >.navbar-foot {
        width: 100%;
      }
    }
  }
  .logo {
    width: 290px;
  }
  .is-online {
    a {
      color: $white;
      &:hover {
        color: $primary;
      }
    }
  }
}

#main {

  @include until ($desktop) {
    // margin-top:120px
  }
  > .container {
  background-image: linear-gradient(90deg,rgba(216,216,216,1) 0px, transparent 1px);
    background-size: calc(100% / 3 - 0.3px);
  background-position: 0 0;
    &::before {
      content:"";
      display: block;
      position: absolute;
      width: 100vw;
      height: 1px;
      background-image: linear-gradient(rgba(216,216,216,1) 0px, transparent 1px);
      left: 50%;
      top: 0;
      transform: translate(-50%, 0%);
    }
    > div {
      margin: 0;
      padding: 0;
    }
  }
}

.title {
  color: $text-strong;
}
figure.image {
  img {
    object-fit: cover;
  }
}


.fullLeft {
  float: left;
  width: 100%;

}

////////////////////////////////

.title {
  &[class^='has-background'],&[class*='has-background'] {
    padding: 0.4em;
  }
}

////////////////////////////////

details
{
  transition: height 1s ease;
  overflow: hidden;
  summary {
    width: 100%;
    list-style: none;
    cursor: pointer;
    position: relative;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    &::-webkit-details-marker,&::marker {
      position: absolute;
      right: 0;
      display: none;
    }
    &::after {
      content:"";
      float: right;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0,-50%);
      color: $black;
      font-family: icomoon;
    }
  }
  .dropdown-content {
    padding-top: 0.5rem;
    padding-bottom: 1.5rem;
    border: 0px;
    background: transparent;
    box-shadow: none

  }
  border-bottom: 1px solid $black;
}


details[open] {
  summary {
    &::after {
      content:"";
    }
  }
  summary ~ * {
    animation: sweep .5s ease-in-out;
  }
}

@keyframes sweep {
  0%    {opacity: 0; margin-left: -10px}
  100%  {opacity: 1; margin-left: 0px}
}


///// container maxwidth on desktop
.container {
  @include desktop {
    max-width: 1180px;
  }
}



/////////////accordion
input[name="accordion-select"], .summary-block {
  display: none;
}
.summary {
  transition: height 1s ease;
  overflow: hidden;
  border-bottom: 1px solid #000;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  position: relative;
  &::after {
    content: "";
    float: right;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    color: #000;
    font-family: icomoon;
  }
}
label.summary-heading {
  width: 100%;
  list-style: none;
  cursor: pointer;
  position: relative;
  display: inline-block;
  margin-bottom:0 !important; ;
}



@keyframes sweep {
  0%    {opacity: 0; margin-left: -10px}
  100%  {opacity: 1; margin-left: 0px}
}


/* the magic */
/* the magic */

@for $i from 1 through 69 {
  #panel-#{$i}:checked ~ .summary.sum-#{$i}  {
    &::after {
      content:"";
    }

    ~ * {
      animation: sweep .5s ease-in-out;
    }
  }

  #panel-#{$i}:checked ~ .summary > .body-#{$i} {
    display: block;
    padding-top: 1.5rem;
  }
}

.small-padding-2rem {
  @include until ($desktop) {
    padding: 2rem !important;
  }
}

.intern-menu {
  .active {
    // display: none;
  }
  a {
    color: $white;
    &:hover {
      color: $yellow;
    }
  }
}

.pagination {
  justify-content: center;
  margin-bottom: 2rem;
  .page {
    a {
      padding: 2px 15px;
      color: $picblue;
      line-height: 1;
      display: block;
      &:hover {
        background: $picblue;
        color: $white !important;
      }
    }
    &.current {
      padding: 2px 15px;
      background: $picblue;
      color: $white;
      line-height: 1;
    }
  }
}

@include until ($desktop) {
  .small-text-center {
    text-align: center;
  }
}

.fobi-front-form {
  .field {
    width: 100%;
    float: left;
    padding-right: 1rem;
    @include mobile {
      width: 100% !important;
      padding-left: 1rem;
    }
    &#id_first_name {
      width: 50%;
    }
    &#id_last_name {
      width: 50%;
    }
    &#id_courriel {
      width: 50%;
    }
    &#id_telephone {
      width: 50%;
    }
    &#id_societe {
      width: 50%;
    }
    &#id_objet {
      width: 50%;
    }
    .label {
      display: none;
    }
    &#id_fichier {
      .label {
        display: block;
      }
      input {
        border: none;
      }
    }
    &#id_confidentialite-des-donnes {
      margin-top: 1rem;
      .control {
        display: flex;
        align-items: baseline;
        input {
          width: inherit;
          margin-right: 10px;
        }
      }
    }
    .g-recaptcha {
      display: flex;
      justify-content: center;
    }
    input, textarea, select {
      width: 100%;
      border: 1px solid #c6c6c6;
      border-radius: 3px;
      padding: 10px;
      font-size: 15px;
      &::placeholder {
        color: $black;
      }
    }
    input {

    }
  }
  p {
    font-size: 16px;
  }
}
