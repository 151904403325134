html {
  overscroll-behavior: none;
}

body {
  background-color: $body-background-color;

}

::selection {
  background: lighten($color: $primary-color, $amount: 10);
  color: $white;
}

.is-inter {
 font-family: $tertiary-font;
}

/* preloader */

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

ol,
ul {
  list-style-type: none;
  margin: 0px;
}

img {
  vertical-align: middle;
  border: 0;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

a,
button,
select {
  cursor: pointer;
  transition: .2s ease;

  &:focus {
    outline: 0;
  }
}

a.text-dark{
  &:hover{
    color: $primary-color !important;
  }
}

a:hover {
  color: $primary-color;
}



.section {
  padding-top: 80px;
  padding-bottom: 80px;

  &-title {
    margin-bottom: 80px;
    font-family: $tertiary-font;
  }
  &.is-small{
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.has-background-cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

/* overlay */

.overlay {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: $black;
    opacity: .5;
  }
}

.outline-0 {
  outline: 0 !important;
}

.d-unset {
  display: unset !important;
}

.has-background-primary {
  background: $primary-color !important;
}

.has-background-matisse {
  background: $primary-color !important;
}

.has-background-light-gray {
  background: $light-gray !important;
}

.has-background-gray{
  background: $gray !important;
}

.has-text-primary {
  color: $primary-color !important;
}

.has-text-color {
  color: $text-color;
}

.has-text-dark {
  color: $text-color-dark !important;
}

.has-text-light {
  color: $text-color-light !important;
}

.has-text-lightblue {
  color: $lightblue !important;
}

.has-text-blue {
  color: $picblue !important;
}

.has-text-yellow {
  color: $yellow !important;
}

.has-text-space {
  letter-spacing:0.4em;
}

.has-text-weight-black {
  font-weight: $weight-black !important;
  letter-spacing: -0.02rem;}

.has-text-weight-bolder {
  font-weight: 800 !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mr-20{
  margin-right: 20px;
}

.zindex-1 {
  z-index: 1;
}

.zindex-10 {
  z-index: 10;
}

.rounded-lg{
  border-radius: 15px;
}

.overflow-hidden {
  overflow: hidden;
}

.font-secondary {
  font-family: $secondary-font !important;
}

.font-tertiary {
  font-family: $tertiary-font !important;
}

.shadow {
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.125) !important;
}

.bg-dark{
  background-color: #222222 !important;
}

.has-bg-fit {

}
.has-vcentered {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.bg-image {
  position: absolute;
  left: 0;
  &.is-fit {

    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}


/* icon */
.is-icon-fix-size {
  font-size: 45px;
  height: unset;
  width: unset;
}

.icon-bg {
  height: 100px;
  width: 100px;
  line-height: 100px;
  text-align: center;
}

.icon-light {
  color: #c8d5ef;
}
.icon-circle-container {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 75%);
  background: #FFF;
  border: 1px solid #ccc;
  border-radius: 100%;
  padding: 1rem;
}
.icon-circle {
  padding:0.5em;
  display: inline-block;
  border-radius: 20rem;
}

/* /icon */

/* slick slider */
.slick-dots,.splide__pagination{
  text-align: center;
  padding-left: 0;
  width: 100%;
  li{
    display: inline-block;
    margin: 2px !important;
    &.slick-active,&.is-active{
      button{
        background: $picblue;
        width: 25px;
      }
    }
    button{
      height: 6px;
      width: 12px;
      background: rgba($color: $grey-light, $alpha: .9);
      color: transparent;
      border-radius: 10px;
      overflow: hidden;
      transition: .2s ease;
      border: 0;
      &.is-active {
        background: $picblue;
        width: 25px;
      }
    }
  }
}
/* /slick slider */

/* form */

#formHero {

  .input{
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid $border-color;
    box-shadow: none;
    &:focus{
      border-color: $primary-color;
      outline: 0;
      box-shadow: none !important;
    }
  }

  padding: 8px;
  background: white;
  border-radius: 6px;
  box-shadow: rgba(255,255,255,0.5) 0 0 0px 8px;
  .field.has-addons .control:last-child:not(:only-child) .button {
    border-radius: 8px;
  }
  .control {
    &:not(:first-child) {
      &:not(:last-child) {
        &:before {
          display: block;
          content: '';
          width: 1px;
          height: 100%;
          background: rgba($skyblue,0.3);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
        }
      }
    }
  }
  input {
    border-bottom: 0px;
    font-size: 0.9rem;
    line-height: 1.8;
    padding-top: calc(1.25em - 1px);
    padding-bottom: calc(1.25em - 1px);
    height: 3.1rem;
    padding-left: 2em;
    &:hover,&:focus {
      outline-width: 0;outline: none;
      background: transparent;
    }
  }
  .select {
    &.is-small {
      height: 3.1rem;
    }
    select {
      font-size: 0.9rem;
      padding-top: auto;
      padding-bottom: auto;
      height: 3.1rem;
      border-width: 0;
      padding-left: 1.75em;
      &:hover,&:focus {
        outline-width: 0;outline: none;
        background: transparent;border-color: transparent;
        box-shadow: none;
      }
    }
  }
  .select-icon {
    height: 3.1rem;
  }
  .control.has-icons-left .icon{
    width: 1.5rem;
  }
}
textarea.input{
  height: 120px;
}
/* /form */

/* card */
.card{
  border: 0;
  &-header{
    border: 0;
  }
  &-footer{
    border: 0;
  }
}
/* /card */

.list-hero-social{
  position: relative;
  z-index: 1;
  background: transparent;
  box-shadow: none;
  border: none;
  width: 40px;
  margin: 30px 0 0 50px;
  .list-item{
    border: none;
    background: transparent;
  }
}

.is-rounded{
  border-radius: 50%;
}

.image.is-full{
  width: 100%;
}

.has-border-top{
  border-top: 1px solid $border-color;
}

.has-border-bottom{
  border-bottom: 1px solid $border-color;
}

.is-border-dark{
  border-color: #343a40!important;
}

.is-border-white{
  border-color: $white !important;
}

.has-line-top{
  position: relative;
  &::before {
    content:"";
    height: 5px;
    top: -4px;
    left: 0;
    display: block;
    width: 100%;
    background: $black;
    position: absolute;
  }
}
.has-line-bottom{
  position: relative;
  &::before {
    content:"";
    height: 5px;
    bottom: 4px;
    left: 0;
    display: block;
    width: 100%;
    background: $black;
    position: absolute;
  }
}

.has-line-separator{
  position: relative;
  &::before {
    content:"";
    height: 1px;
    top: 0px;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: -1;
    display: block;
    width: 200vw;
    background: linear-gradient( #d8d8d8 0px, transparent 1px);
    position: absolute;
  }
}

/////////////// BG WHITE grid
.has-bg-block {
  padding: 1.5px !important;
  > div.is-bg {
    background: $white;
    width: 100%;
    height: 100%;
    padding:2rem ;
  }
}
/////////////// BG WHITE

.has-padding-top-15{
  padding-top: 15px;
}

.has-padding-20{
  padding: 20px;
}

.has-no-shadow {
  box-shadow: 0 0 0 rgba(0,0,0,0);
}

.buttons{
  .button{
    input{
      width: 0;
      visibility: hidden;
    }
  }
}

.has-image-fit {
  object-fit: cover;
  position: relative;
  .is-fit {

    width: 100%;
    height: 100%;
    position: relative;
    max-width: none;
    object-fit: cover;
  }
}
.tag.is-tag-article {
  position: absolute;top: 0;left:0;
  border-radius: 0 0 $radius-big  0 !important;
  padding-left:$radius-big ;
  padding-right:$radius-big ;
  padding-top:$radius-big/2 ;
  padding-bottom:$radius-big/2 ;
  font-size: 0.75rem !important;
  z-index: 2;
}

.card {
  &.is-cardspecial {
    height: 100%;
    display: flex;
    flex-direction: column;
    .is-image {
      height: 100%;
      align-items: baseline;
      align-self: flex-end;
      justify-content: flex-end;
      display: flex;
      flex-direction: column;
    }
    .is-image-full {
      height: 100%;
      align-items: baseline;
      align-self: flex-end;
      justify-content: flex-end;
      display: flex;
      flex-direction: column;
    }
  }
  &.is-full {
    width: 100%;
    height: 100%;
  }

}
.is-bl-bluetrans {
  mix-blend-mode: multiply;
}


.columns {
 &.is-vbottom {
   align-items:flex-end;
 }
}

.has-shadow-high {
  box-shadow: 0 8px 90px rgba(0,0,0,0.1);
}