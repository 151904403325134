
body.nothree  {
  #main {margin-top: 120px;}
.navbar {
  background: $picblue;
  @include mobile {
    position: relative;
  }
    .logo {
      background-image: url("/static/images/logo-white-2.png");
    }
      .navbar-burger {
        color: $white;
        span {
          color: $white;
        }
      }

  }
}

#closeNav {
  position: absolute;right: 3rem;
  top: 3rem;
  height: auto;
  width: auto;
  z-index: 100;
  @include until ($tablet) {
    right: 1rem;
    top: 2rem;
    .txt {display: none}
  }
}

.close-burger {
  color:$white;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  &:hover {
    color:$white;
  }
  .txt {
    padding: 0 1rem;
    position: relative;
    font-size: 21px;
  }
  .cross {
    position: relative;
    width: 40px;
    height: 40px;
  }
  span {
    background-color: $white;
    display: block;
    height: 3px;
    left: calc(0% - 1px);
    top: calc(50% - 1px);
    position: absolute;
    transform: rotate(45deg);
    transform-origin: center;
    transition-duration: 86ms;
    transition-property: background-color, opacity, transform;
    transition-timing-function: ease-out;
    width: 48px;
    &:nth-child(2) {
      transform: rotate(-45deg);
    }
  }

}
.navbar {

  .navbar-menu {
    display:flex;
    @include from ($tablet) {
      width: 40%;
      background: transparent;
      box-shadow:0 0 0;
    }
  }
}
#menu {

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: url("/static/images/backgrounds/menu.png");
  background-color: $highblue;
  background-size: cover;
  margin-left: -100%;
  z-index: 50;
  .navbar-menu {
    padding: 1rem 2rem;
    position: absolute;
    height: 100%;
    width: 50%;
    top:0;
    left: 0;
    background-color: $highblue;
    flex-direction: column;
    @include until ($tablet) {
      padding: 1rem 1rem;
      z-index: 50;
      width: 100%;
      display: flex;
      .button.is-normal {
        font-size: 1rem;
      }
    }
    @include from ($tablet) {
      display: flex;
      width: 40%;
    }
    @include from ($desktop) {
      width: 40%;
    }
    @include from ($widescreen) {
      width: 33%;
    }
    .navbar-item, .navbar-link {
      padding: 0.25rem 0.75rem;
    }
    .navbar-item img {
      max-height: none;
      height: 70px;
      width: 358px;
      @include until ($tablet) {
        height: auto;
        width: 74vw;
      }
    }

  }
  .navbar-start {
    position: absolute;
    left: 10%;
    //display: flex;
    display: block;
    width: 90%;
    max-height: calc(100vh - 300px);
    overflow-y:auto;
    overflow-style: marquee-line;
    scrollbar-width: thin; /* "auto" or "thin" */
    scrollbar-color: #00f transparent; /* scroll thumb and track */
    transition: 0.5s ease-in-out;

    @include mobile() {
      left:0vw;
    }
    &.toactived {
      @include mobile() {
        left:-100vw;
      }
    }
    @include mobile() {
      max-height: calc(100vh - 200px);
      top: 100px;
      width: 100%;
    }
    &:hover {
      scrollbar-color: #000 transparent;
    }
    &::-webkit-scrollbar-track,*::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb,*::-webkit-scrollbar-thumb {
      background-color: #000;
      border-radius: 20px;
      border: 6px solid transparent;
      background-clip: content-box;
    }
    &::-webkit-scrollbar-thumb:hover,*::-webkit-scrollbar-thumb:hover {
      background-color: #00f ;
    }

    //justify-content: center;
    //align-items: left;
    //flex-direction: column;
    top:170px;
  }

  .navbar-start .is-niv1 {
    margin: 0px 0;
    z-index: 2;
    float: right;
    text-align: right;
    clear: both;

  }

  .navbar-start .is-niv1 {

    @include mobile() {
      padding: 0.25rem 0.25rem;
    }
      a {
      text-decoration: none;
      color: #fff;
      transition: 2s;
      font-family: 'urbanist', sans-serif;
      font-size: 18px;
      font-weight: 800;
      text-transform: none;
      mix-blend-mode: difference;
        @include until ($desktop) {
          font-size: 21px;
        }
      &:hover {
        background: transparent;
        color: $yellow;
      }
    }
    &.actived {
      a {
        color: $yellow;
      }
    }
  }

  .social {
    position: absolute;
    bottom: 0;
    display: flex;
    height: 80px;
    width: 100%;
    @include mobile() {
      height: 60px;
    }
  }

  .social ul {
    display: flex;
    justify-content: space-between;
    width: 90%;
  }
  .social ul li {
    margin: 0 10px;
    @include mobile() {
      transform: translate(0%,0%) !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &.devis {
      width: 100%;
    }
  }

  .social ul li a {
    text-decoration: none;
    color: #fff;
    &.icon {
      font-size: 18px;
    }
  }

  .bg {
    left: 0;
    position: absolute;
    width: 250px;
    filter: grayscale(1);
    height: 300px;
    mix-blend-mode: difference;

  }

  .bg1 {
    top: 45px;
    left: 80px;
    background: url('../img/bg1.jpg') no-repeat 50% 50%;
    block-size: cover;
    opacity: 0;
    transform: translateY(-50%);
    transition: 1s ease-in-out;
  }

  .bg2 {
    top: 100px;
    left: 150px;
    background: url('../img/bg2.jpg') no-repeat 50% 50%;
    block-size: cover;
    opacity: 0;
    transform: translateY(-50%);
    transition: 1s ease-in-out;
  }

  .bg3 {
    top: 250px;
    left: 200px;
    background: url('../img/bg3.jpg') no-repeat 50% 50%;
    block-size: cover;
    opacity: 0;
    transform: translateY(-50%);
    transition: 1s ease-in-out;
  }

  .bg4 {
    top: 400px;
    left: 200px;
    background: url('../img/bg4.jpg') no-repeat 50% 50%;
    block-size: cover;
    opacity: 0;
    transform: translateY(-50%);
    transition: 1s ease-in-out;
  }

  .li1:hover ~ .bg1,
  .li2:hover ~ .bg2,
  .li3:hover ~ .bg3,
  .li4:hover ~ .bg4 {
    opacity: 1;
    transform: translateY(0%);
    transition: .6s ease-in-out;
  }

  .ul li a:hover {
    transition: 2s;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px white;
  }
}

.menu .button {
  color: #fff;
}
.navbar-second {
  position: absolute;
  left: 50%;
  display: flex;
  width: 50%;
  height: calc(100vh - 170px);
  justify-content: center;
  align-items: left;
  flex-direction: column;
  top:170px;
  transition: 0.5s ease-in-out;
  @include mobile() {
    width: 100%;
    left: 00%;
    top: 100px;
    z-index: -1;
  }
  @include from ($tablet) {
    width: 60%;
    left: 40% !important;
    .backMenu {
      display: none;
    }
  }
  @include from ($desktop) {
    width: 60%;
    left: 40% !important;
    .backMenu {
      display: none;
    }
  }
  @include from ($widescreen) {
    width: 67%;
    left: 33% !important;
  }
  .navbar-dropright {
    position: absolute;left: 10%;
    top: 0 ;
    height: 100%;
    width: 80%;
    opacity: 0;
    * {color:$white}
  }
  .is-niv2 {
    .navbar-item {
      font-family: 'urbanist';
      font-weight: 200;
      line-height: 1;
      font-size: 27px;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
      &::before {
        content:'-';
        font-size:32px;
        display: inline-flex;
        line-height: 1px;
        align-items: center;
        justify-content: center;

      }
      @include until ($desktop) {
        font-size: 27px;
        font-weight: 600;
      }
      @include mobile() {
        font-size: 24px;
        font-weight: 600;
      }
      &:hover {
        background: transparent;
        color:$yellow;
      }
    }
  }
}
