//glacial
@include font-face("glacial","../fonts/glacial/GlacialIndifference-Bold", bold, null, eot woff2 woff);
@include font-face("glacial","../fonts/glacial/GlacialIndifference-Regular", regular, null, eot woff2 woff);
//urbanist
@include font-face("urbanist","../fonts/urbanist/Urbanist-Light", 200, null,   woff2);
@include font-face("urbanist","../fonts/urbanist/Urbanist-Regular", 400, null,  woff2);
@include font-face("urbanist","../fonts/urbanist/Urbanist-SemiBold", 500, null,  woff2);
@include font-face("urbanist","../fonts/urbanist/Urbanist-Bold", 600, null,  woff2);
@include font-face("urbanist","../fonts/urbanist/Urbanist-ExtraBold", 700, null,  woff2);
@include font-face("urbanist","../fonts/urbanist/Urbanist-ExtraBold", 800, null,  woff2);
