$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts/icons" !default;

$icon-handicap: "\e913";
$icon-twitter-x: "\e914";
$icon-arrowlineL: "\e90e";
$icon-arrowlineR: "\e90f";
$icon-arrowlineB: "\e910";
$icon-arrowlineT: "\e911";
$icon-back: "\e90c";
$icon-arrow-r: "\e90d";
$icon-copro: "\e906";
$icon-auscultation: "\e900";
$icon-drone: "\e901";
$icon-foncier: "\e902";
$icon-studio: "\e903";
$icon-top_extern: "\e904";
$icon-top_intern: "\e905";
$icon-cloud: "\e90b";
$icon-twitter: "\e907";
$icon-brand: "\e907";
$icon-tweet: "\e907";
$icon-social: "\e907";
$icon-instagram: "\e908";
$icon-brand1: "\e908";
$icon-social1: "\e908";
$icon-youtube: "\e909";
$icon-vimeo: "\e915";
$icon-linkedin: "\e912";
$icon-brand2: "\e909";
$icon-social2: "\e909";
$icon-facebook: "\e90a";
$icon-brand3: "\e90a";
$icon-social3: "\e90a";
